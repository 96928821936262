@import 'variable';
@import 'mixin';

/* ======================================================================
 p_top
====================================================================== */
// l_main_visual_top
.l_main_visual_top {
}
#p_top {
  .l_content {
    position: relative;
    overflow: hidden;
    &:before {
      content:'';
      width: 1920px;
      height: 3300px;
      background: url(../img/p_top/mainbg.png);
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      @include mq_max(768) {
        display: none;
      }
    }
  }
  .l_main {
    margin: 0 auto;
    width: auto;
    @include mq_max(768) {
      background: url(../img/p_top/sp/bg.png);
      background-size: cover;
    }
  }
  .sec {
    .inner {
      width: 1100px;
      margin: 0 auto;
      @include mq_max(768) {
        width: 100%;
      }
    }
    &.sec01 {
      padding: 100px  0 0;
      @include mq_max(768) {
        padding: 40px 0;
      }
      >.inner {
        >img {
          @include mq_max(768) {
            width: 90.625%;
            margin: 0 auto;
          }
        }
      }
      .ttl {
        padding: 40px 0;
        text-align: center;
        color: #fff;
        font-size: 56px;
        @include mq_max(768) {
          font-size: 32px;
          line-height: 1.2;
        }
      }
      .wrap {
        width: 980px;
        margin: 0 auto;
        @include mq_max(768) {
          width: 100%;
          padding: 0 4.6875%;
        }
      }
      .presidentbox {
        margin: 180px 0 0;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        @include mq_max(768) {
          margin: 50px 0 0;
          flex-wrap: wrap;
          padding: 0 4.6875%;
        }
        .imgwrap {
          width: 550px;
          margin: -80px 0 0;
          @include mq_max(768) {
            width: 100%;
            margin: 0;
          }
        }
        .txtwrap {
          width: calc(100% - 550px);
          padding: 50px 74px;
          background: url(../img/p_top/bg@2x.png);
          background-size: cover;
          @include mq_max(768) {
            width: 100%;
            padding: 30px 15px;
            background: rgba(255,255,255,.85);
          }
          >img {
            @include mq_max(768) {
              width: 245px;
              margin: 0 auto;
            }
          }
          .u_txt_1 {
            margin: 40px 0 0;
            color: #020202;
            @include mq_max(768) {
              font-size: 14px;
              line-height: 2;
              margin: 20px 0 0;
            }
          }
        }
      }
      .lists {
        margin: 100px 0 0;
        padding: 0 0 50px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        position: relative;
        z-index: 3;
        @include mq_max(768) {
          flex-wrap : wrap;
          margin: 60px 0 0;
          padding: 50px 0 0;
        }
        &:before {
          content: '';
          background: #020255;
          width: 100%;
          height: 260px;
          position: absolute;
          top: 80px;
          left: -80px;
          z-index: -1;
          @include mq_max(768) {
            width: 70%;
            height: 100%;
            right: 0;
            top: 0;
            left: initial;
          }
        }
        li {
          @include mq_max(768) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            width: 100%;
            &:first-child {
              justify-content: flex-end;
            }
            &:nth-child(3) {
              justify-content: flex-end;
            }
            &:nth-child(2) {
              >a {
                >p {
                  text-align: right;
                }
              }
            }
            &:not(:first-child) {
              margin: 30px 0 0;
            }
          }
          >a {
            display: block;
            @include mq_max(768) {
              width: 325px;
            }
            .imgwrap {
              overflow: hidden;
              >img {
                transition: all .1s linear;
              }
            }
            &:hover {
              position: relative;
              .imgwrap {
                position: relative;
                >img {
                  z-index: 3;
                  transform-origin: center center;
                  transform: scale(1.05);
                }
              }
              >p {
                position: absolute;
                z-index: 4;
                bottom: -30px;
                left: 0;
                font-size: 60px;
                color: #C8AF42;
                font-weight: bold;
                text-shadow: 13px -12px 3px rgba(0, 0, 0, .5);
                @include mq_max(768) {
                  position: relative;
                }
              }
            }
            >p {
              position: absolute;
              bottom: 20px;
              color: #fff;
              font-size: 20px;
              @include mq_max(768) {
                position: static;
                margin: 10px 0 0;
              }

            }
          }
        }
      }
      .slider {
        margin: 150px 0 0;
        padding: 0 0 40px;
        @include mq_max(768) {
          margin: 60px 0 0;
          padding: 0 0 20px;
        }
        .item {
          width: 525px;
          position: relative;
          @include mq_max(768) {
            width: 280px;
          }
          a {
            display: block;
            position: relative;
            transition: all .2s linear;
            &:before {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              content:url(../img/p_top/icon.svg);
              pointer-events: none;
              @include mq_max(768) {
                content:url(../img/common/sp/icon_link.svg);
              }
            }
            &:hover {
              opacity: .7;
            }
            .movietxt {
              text-align: center;
              font-size: 16px;
              margin: 15px 0 0;
              color: #fff;
              @include mq_max(768) {
                font-size: 12px;
                line-height: 1.8;
              }
            }
          }

        }
        .slick-slide {
          margin: 0 15px;
          @include mq_max(768) {
            margin: 0 7px;
          }
        }
        .slick-dots {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          justify-content: center;
          @include mq_max(768) {
            width: calc(100% - 40px);
            left: 20px;
          }
          >li {
            background: #fff;
            width: 157px;
            font-size: 0;
            height: 2px;
            @include mq_max(768) {
              width: calc( (100% - 20px) / 8 );
            }
            &:not(:first-child) {
              margin: 0 0 0 10px;
            }
            &.slick-active {
              height: 4px;
              background: #C8AF42;
            }
            >button {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
